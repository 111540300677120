export const environment = {
  production: true,
  branch: 'master',
  bugsnagApiKey: '121ea2a1a1400f165e54cb2ce7365249',
  api: 'https://dev-api.softbrik.com',
  allo: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  stak: 'https://dpuwkjscmj.execute-api.eu-west-1.amazonaws.com/prod',
  trak: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  file: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  contacts: 'https://4ddie1avdb.execute-api.eu-west-1.amazonaws.com/prod',
  trust: 'https://mowqh62zx8.execute-api.eu-west-1.amazonaws.com/prod',
  health: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  survey: 'https://3841at1ckl.execute-api.eu-west-1.amazonaws.com/prod',
  ws: 'wss://cpqi7oj4yi.execute-api.eu-west-1.amazonaws.com/devTesting',
  export:
    'https://vi1ur5ir2k.execute-api.eu-west-1.amazonaws.com/devTesting/surveys/',
};
